export enum AnalyticsEventName {
  ImpressionEvent = 'ImpressionEvents',
  ClickEvent = 'ClickEvents',
}

export enum AnalyticsEventType {
  Impression = 'impression',
  Click = 'click',
  Gesture = 'gesture',
  Session = 'session'
}

export enum AnalyticActionType {
  Load = 'load',
  Scroll = 'scroll',
  VScroll = 'vScroll',
  Click = 'click',
  Gesture = 'gesture',
}

export enum AnalyticsPageSectionName {
  PDPOverlay = 'pdp_overlay',
  PushToShopifyOverlay = 'push_to_shopify_overlay',
  TopNavbar = 'top_navbar',
  CloutTraining = 'clout_training',
  BottomNavbar = 'bottom_navbar',
  SideNavbar = 'side_navbar',
  CalculatorPopup = 'calculator_popup',
  PricingCalculator = 'pricing_calculator',
}

export enum AnalyticsEventTargetType {
  ViewBonusOffer = 'View Bonus Offer',
  GetDiscountPerOrder = 'Get discount per order',
}

export enum AnalyticsEventAction {
  ProductTileClicked = 'product_tile_clicked',
  VideoPlayStarted = 'video_play_started',
  VideoPlayEnded = 'video_play_ended',
  ProductTileViewed = 'product_tile_viewed',
  PDPOverlayOpened = 'pdp_overlay_opened',
  SellingPriceInputFieldClicked = 'selling_price_input_field_clicked',
  PushToShopifySubmitClicked = 'push_to_shopify_submit_clicked',
  PageViewed = 'page_viewed',
  CategoryBubbleClicked = 'category_bubble_clicked',
  CollectionViewAllClicked = 'collection_view_all_clicked',
  CollectionBannerClicked = 'collection_banner_clicked',
  RoposoCloutLogoClicked = 'roposo_clout_logo_clicked',
  NotificationIconClicked = 'notification_icon_clicked',
  HeaderProfileIcon = 'profile_icon_hovered',
  WishlistIconClicked = 'wishlist_icon_clicked',
  TrainingRequested = 'training_requested',
  PushToShopifyClicked = 'push_to_shopify_clicked',
  PushToShopifyOverlayOpened = 'push_to_shopify_overlay_opened',
  StoreChanged = 'store_changed',
  BottomNavbarItemClicked = 'bottom_navbar_item_clicked',
  SideNavbarItemClicked = 'side_navbar_item_clicked',
  TrustBannerViewed = 'trust_banner_viewed',
  CollectionCarouselScrolled = 'collection_carousel_scrolled',
  CollectionCarouselViewed = 'collection_carousel_viewed',
  BonusOfferCtaClicked = 'bonus_offer_cta_clicked',
  CategoryRibbonNavigated = 'category_ribbon_navigated',
  CategoryRibbonViewed = 'category_ribbon_viewed',
  HelpIconClicked = 'help_icon_clicked',
  ResetButtonClicked = 'reset_button_clicked',
  CalculationTriggered = 'calculation_triggered',
  CalculatorCtaClicked = 'calculator_cta_clicked',
  VideoDownloadClicked = 'video_download_clicked',
  FbAdLinkClicked = 'fb_ad_link_clicked',
  VisibleShipping = 'visible_shipping',
  DownloadAssetClicked = 'download_asset_clicked',
  ImageScrolled = 'image_scrolled',
  WishlistClicked = 'wishlist_clicked',
  CalculatorViewed = 'calculator_viewed',
  BonusPopupViewed = 'bonus_popup_viewed',
  ShippingChargeClicked = 'shipping_charge_clicked',
  ShippingChargesViewed = 'shipping_charges_viewed',
  RtoRvpChargesClicked = 'rto_rvp_charges_clicked',
  RtoRvpChargeViewed = 'rto_rvp_charges_viewed',
  InventoryTooltipViewed = 'inventory_tooltip_viewed',
  ProductDescriptionViewed = 'product_description_viewed',
  Scroll = 'scroll',
  CategoryNavbarViewed = 'category_navbar_viewed',
  CategoryNavbarScrolled = 'category_navbar_scrolled',
  CategoryItemClicked = 'category_item_clicked',
}

export enum AnalyticsEntityType {
  Product = 'product',
  Collection = 'collection',
  Category = 'category',
  PaymentSheet = 'payment_sheet',
  Order = 'order',
  FeedbackStackId = 'feedbackStackId',
}

export enum AnalyticsEvent {
  EntityID = 'entity_id',
  EntityType = 'entity_typ',
  Extras = 'ext',
  Position = 'position',
  RowPosition = 'rowPosition',
  ColumnPosition = 'columnPosition',
  SourcePageId = 'sourcePageId',
  PageSectionName = 'pageSectionName',
  VideoId = 'videoId',
  VideoLength = 'videoLength',
  VideoTotalWatchDuration = 'totalWatchDuration',
  VideoLoopCount = 'loopCount',
  VideoLatestWatchDuration = 'latestWatchDuration',
  SellingPrice = 'SellingPrice',
  StoresPrimaryKey = 'storesPrimaryKey',
  PageUrl = 'pageUrl',
  CategoryUrl = 'categoryUrl',
  Location = 'location',
  CollectionId = 'collectionId',
  CollectionName = 'collectionName',
  CarouselPosition = 'carouselPosition',
  Price = 'price',
  Inventory = 'inventory',
  SupplierRating = 'supplierRating',
  ProductCCode = 'productCCode',
  Margin = 'margin',
  CloutPrice = 'cloutPrice',
  NotificationCount = 'notificationCount',
  ItemName = 'itemName',
  ItemHeading = 'itemHeading',
  ProductCount = 'productCount',
  MoreProducts = 'moreProducts',
  Direction = 'direction',
  Type = 'type',
  TotalProducts = 'totalProducts',
  Data = 'data',
  Id = 'id',
  UnitsSold = 'unitsSold',
  DeliveryRate = 'deliveryRate',
  VisibleShipping = 'visibleShipping',
  CalculatorSellingPriceUsed = 'calculator_selling_price_used',
  RtoCharges = 'rto_charges',
  MarginPerOrder = 'margin_per_order',
  ExpectedOrders = 'expected_orders',
  ConfirmedOrderPerc = 'confirmed_order_perc',
  ExpectedDeliveryPerc = 'expected_delivery_perc',
  AdSpendPerOrder = 'ad_spend_per_order',
  TotalMiscCharges = 'total_misc_charges',
  NetProfit = 'net_profit',
  NetProfitPerOrder = 'net_profit_per_order',
  VideoUrl = 'video_url',
  Weight = 'weight',
  GstPercentage = 'gst_percentage',
  VariantInfo = 'variant_info',
  ErrorResponse = 'errorResponse',
  FeedbackStack = 'feedbackStack',
  FeedbackStackId = 'feedbackStackId',
  ProductId = 'productId',
  FbAdCount = 'fb_ad_count',
  Asset = 'asset',
  AssetLink = 'asset_link',
  Action = 'action',
  ShippingCharge = 'shipping_charge',
  ProductPrice = 'product_price',
  RtoCost = 'rto_cost',
  RvpCost = 'rvp_cost',
  CategoryName = 'category_name',
  CategoryId = 'category_id',
  ScrollDepth = 'scroll_depth',
  ScrollDepthPosition = 'scroll_depth_position',
}

export enum PageName {
  Home = 'home',
  Analytics = 'analytics',
  PDP = 'product_detail',
  ManageOrder = 'manage_orders',
  ManageNDR = 'manage_ndr',
  ManageProducts = 'manage_products',
  SourceProducts = 'source_products',
  RTOPinCodeList = 'rto_pincode_list',
  RtoFaq = 'rto_faqs',
  Reports = 'reports',
  Payments = 'payments',
  Whatsapp = 'whatsapp_dashboard',
  CloutUpdate = 'clout_updates',
  Profile = 'profile',
  StoreManager = 'store_manager',
  StaffAccessManagement = 'staff_access_management',
  CreateStaffAccount = 'create_staff_account',
  LinkShopfiy = 'link_shopify_store',
  Login = 'login',
  StaffLogin = 'staff_login',
  Collection = 'collection',
  Category = 'category',
  Search = 'text_search_results',
  ImageSearch = 'image_search_results',
  CollectionVideoFeed = 'collection_video_feed',
  SearchVideoFeed = 'search_video_feed',
  DiscoverVideoFeed = 'discover_video_feed',
  WishlistVideoFeed = 'wishlist_video_feed',
  CategoryVideoFeed = 'category_video_feed',
  Wishlist = 'wishlist',
}

export const pageName = [
  { url: '/dashboard/analytics', name: PageName.Analytics },
  { url: '/product/', name: PageName.PDP },
  { url: '/order/manage/', name: PageName.ManageOrder },
  { url: '/manage-ndr/', name: PageName.ManageNDR },
  { url: '/manage-products/', name: PageName.ManageProducts },
  { url: '/source-product', name: PageName.SourceProducts },
  { url: '/rto-intelligence/pincodes', name: PageName.RTOPinCodeList },
  { url: '/rto-intelligence/faqs', name: PageName.RtoFaq },
  { url: '/reports', name: PageName.Reports },
  { url: '/payments', name: PageName.Payments },
  { url: '/dashboard/whatsapp', name: PageName.Whatsapp },
  { url: '/clout-updates', name: PageName.CloutUpdate },
  { url: '/profile', name: PageName.Profile },
  { url: '/store-manager', name: PageName.StoreManager },
  { url: '/staff-access-management', name: PageName.StaffAccessManagement },
  { url: '/staff-account/create', name: PageName.CreateStaffAccount },
  { url: '/link-shopify', name: PageName.LinkShopfiy },
  { url: '/login', name: PageName.Login },
  { url: '/staff-login', name: PageName.StaffLogin },
  { url: '/collection/', name: PageName.Collection },
  { url: '/category/', name: PageName.Category },
  { url: '/search?type=image', name: PageName.ImageSearch },
  { url: '/search', name: PageName.Search },
  { url: '/video-feed/collection', name: PageName.CollectionVideoFeed },
  { url: '/video-feed/search', name: PageName.SearchVideoFeed },
  { url: '/video-feed/discover', name: PageName.DiscoverVideoFeed },
  { url: '/video-feed/wishlist', name: PageName.WishlistVideoFeed },
  { url: '/video-feed/category', name: PageName.CategoryVideoFeed },
  { url: '/wishlist', name: PageName.Wishlist },
  { url: '/', name: PageName.Home, isHome: true },
];
